import { Box, Container, Typography } from "@mui/material";
import TitleSection from "../../components/TitleSection";

import { AnimationOnScroll } from "react-animation-on-scroll";

const NetworkExplorer = () => {
  return (
    <Box
      id="SectionRestakenToken"
      className="container-section margin minvh100"
      sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
    >
      <Container className="container-in margin" sx={{ py: 10, px: "0 !important" }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="32px">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
            <TitleSection Title="Symbiotic Validated Services Explorer" sx={{ textTransform: "initial" }} />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
            <Typography variant="subtitle1" align="center">
              Gain instant insights into the evolving restaking landscape on Symbiotic, with a detailed view of
              Networks, Curators, LRTs, and more—all in one place.
            </Typography>
            <Typography variant="h4" textAlign="center" sx={{ mt: 4 }}>
              Coming Soon...
            </Typography>
          </AnimationOnScroll>
        </Box>
      </Container>
    </Box>
  );
};

export default NetworkExplorer;
