import React, { useEffect, useState } from "react";
import "./assets/css/App.css";
import "animate.css/animate.min.css";
import { createMuiTheme } from "./themes";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import HeaderAppBar from "./components/HeaderAppBar";
import BackTop from "./components/BackTop";
import SectionStart from "./sections/Start";
import SectionVision from "./sections/Vision";
import SectionService from "./sections/Service";
import SectionWhy from "./sections/Why";
import SectionMetrics from "./sections/Metrics";
import Footer from "./sections/Footer";
import SectionFAQ from "./sections/FAQ";
import SectionAssociation from "./sections/Association";
import SectionRestakenToken from "./sections/RestakenToken";
import NetworkExplorer from "./sections/NetworkExplorer";
import Loading from "./components/Loading";
import { Box } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const theme = createMuiTheme();
  const [loading, setLoading] = useState(true);
  const [active_backtop, setBackTop] = React.useState(false);

  const queryClient = new QueryClient();
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);
  useEffect(() => {
    window.onscroll = () => (window.pageYOffset > 100 ? setBackTop(true) : setBackTop(false));
  });
  return (
    <MuiThemeProvider theme={theme}>
      {loading ? (
        <Loading />
      ) : (
        <QueryClientProvider client={queryClient}>
          <Box id="app">
            <HeaderAppBar />
            <SectionStart />
            <SectionRestakenToken />
            <NetworkExplorer />
            <SectionVision />
            <SectionService />
            <SectionWhy />
            <SectionMetrics />
            <SectionAssociation />
            <SectionFAQ />
            <Footer />
            {active_backtop && <BackTop />}
          </Box>
        </QueryClientProvider>
      )}
    </MuiThemeProvider>
  );
}

export default App;
