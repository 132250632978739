import React from "react";
import { Box, Button, CardMedia, Container, SxProps, Theme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VideoAnimation from "./../../assets/videos/video-animation-service.mp4";
import { AnimationOnScroll } from "react-animation-on-scroll";
import TitleSection from "../../components/TitleSection";
import { AMPHORLIQUIDITY_URL } from "../../constants/urls";

// const sxVideoBackground: SxProps<Theme> = (theme: Theme) => ({
//   width: "100%",
//   height: "100%",
//   objectFit: "cover"
// });

const sxVideo: SxProps<Theme> = (theme: Theme) => ({
  width: "100%",
  height: "100%",
  maxHeight: "60vh",
  objectFit: "contain",
});

const SectionService = () => {
  return (
    <Box
      id="SectionService"
      className="container-section"
      sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
    >
      {/*
      <CardMedia
        component="video"
        sx={sxVideoBackground}
        src={VideoBackground}
        autoPlay={true}
        controls={false}
        loop={true}
        muted={true}
        playsInline={true}
      />
      */}
      <Container className="container-in margin" sx={{ zIndex: 1, py: 6 }}>
        <TitleSection Title="Yield Optimizer Vault" />
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
          <CardMedia
            component="video"
            sx={sxVideo}
            src={VideoAnimation}
            autoPlay={true}
            controls={false}
            loop={true}
            muted={true}
            playsInline={true}
          />
          <Box display="flex" justifyContent="center" sx={{ width: "100%", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              href={AMPHORLIQUIDITY_URL}
              target="_blank"
            >
              Provide liquidity
            </Button>
          </Box>
        </AnimationOnScroll>
      </Container>
    </Box>
  );
};

export default SectionService;
