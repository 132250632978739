import { Box, Button, CardMedia, Container, Grid, Typography } from "@mui/material";
import TitleSection from "../../components/TitleSection";

import ImageAmphrETH from "./../../assets/images/amphrETH.svg";
import ImageAmphrBTC from "./../../assets/images/amphrBTC.svg";
import StakingAPR from "./../../assets/images/stakingAPR.svg";
import RestakingAPR from "./../../assets/images/restakingAPR.svg";
import SymbioticPoints from "./../../assets/images/symbioticPoints.svg";
import MellowPoints from "./../../assets/images/mellowPoints.svg";
import AVSLogo from "./../../assets/images/avs-logo.svg";
import MEVLogo from "./../../assets/images/partners/mev-capital-hover.svg";
import AragonLogo from "./../../assets/images/partners/aragon.png";
import NodeinfraLogo from "./../../assets/images/partners/nodeinfra.png";
import ChorusoneLogo from "./../../assets/images/partners/chorusone.png";
import FigmentLogo from "./../../assets/images/partners/figment.jpg";
import BlockscapeLogo from "./../../assets/images/partners/blockscape.png";
import EthenaLogo from "./../../assets/images/partners/ethena.png";
import LombardLogo from "./../../assets/images/partners/lombard.webp";
import RedstoneLogo from "./../../assets/images/partners/redstone.png";
import HyperlaneLogo from "./../../assets/images/partners/hyperlane.svg";
import PrimevLogo from "./../../assets/images/partners/primev.png";
import AmphorLogo from "./../../assets/images/logo-icon-white.svg";
import HyvedaLogo from "./../../assets/images/partners/hyveda.svg";
import LuganodesLogo from "./../../assets/images/partners/luganodes.svg";
// import KilnLogo from "./../../assets/images/partners/kiln.svg";

import { AMPHORLRT_URL, AMPHORBTC_URL, AMPHOR_BRIDGE_URL } from "../../constants/urls";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AnimationOnScroll } from "react-animation-on-scroll";

const RestakenToken = () => {
  return (
    <Box
      id="SectionRestakenToken"
      className="container-section margin minvh100"
      sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
    >
      <Container className="container-in margin" sx={{ py: 10, px: "0 !important" }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap="32px">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
            <TitleSection Title="Liquid Restaked Token" sx={{ textTransform: "initial" }} />
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
            <Box display="flex" gap={2}>
              <Box display="flex" flexDirection="column" gap={1} alignItems="center">
                <CardMedia
                  component="img"
                  src={ImageAmphrETH}
                  alt="amphrETH"
                  title="amphrETH"
                  sx={{
                    display: "inline-block",
                    width: { xs: "60px", lg: "70px" },
                  }}
                />
                <Typography variant="h5">amphrETH</Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap={1} alignItems="center">
                <CardMedia
                  component="img"
                  src={ImageAmphrBTC}
                  alt="amphrBTC"
                  title="amphrBTC"
                  sx={{
                    display: "inline-block",
                    width: { xs: "60px", lg: "70px" },
                  }}
                />
                <Typography variant="h5">amphrBTC</Typography>
              </Box>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut" offset={150}>
            <Typography variant="subtitle1" align="center">
              Liquid Restaked Token (LRT) enables the possibility to secure Symbiotic Validated Services, previously
              whitelisted by node operators and curators partners.
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={{ md: "space-between", sm: "center" }}
              spacing={1}
              sx={{ mt: 1 }}
            >
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={AVSLogo}
                  alt="AVS"
                  title="AVS"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">AVS score</Typography>
                <Typography component="div">(soon)</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={StakingAPR}
                  alt="StakingAPR"
                  title="StakingAPR"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">Staking APR</Typography>
                <Typography component="div">(3.2% Net APY)</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={RestakingAPR}
                  alt="RestakingAPR"
                  title="RestakingAPR"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">Restaking APR</Typography>
                <Typography component="div">(soon)</Typography>
              </Grid>
              {/* <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={SymbioticPoints}
                  alt="SymbioticPoints"
                  title="SymbioticPoints"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">Infrastructure provider</Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={2} textAlign="center">
                <CardMedia
                  component="img"
                  src={MellowPoints}
                  alt="MellowPoints"
                  title="MellowPoints"
                  sx={{
                    display: "inline-block",
                    width: "40px",
                    mb: 1,
                  }}
                />
                <Typography variant="h5">LRT issuer</Typography>
              </Grid> */}
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              sx={{ width: "100%", mt: 2 }}
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                href={AMPHORLRT_URL}
                target="_blank"
              >
                Restake ETH
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                href={AMPHORBTC_URL}
                target="_blank"
              >
                Restake BTC
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                href={AMPHOR_BRIDGE_URL}
                target="_blank"
              >
                Bridge on L2
              </Button>
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
            offset={150}
            style={{ marginTop: 40, width: "100%" }}
          >
            <Typography variant="h5" align="center">
              Powered by Symbiotic Cohort 0 - Cohort 1
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={{ md: "space-between", sm: "center" }}
              spacing={1}
              sx={{ mt: 1 }}
            >
              <Grid item xs={6} sm={4} md={3} textAlign="center">
                <Typography variant="h5">Infrastructure provider</Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", mt: 2 }}
                  flexDirection="column"
                >
                  <CardMedia
                    component="img"
                    src={SymbioticPoints}
                    alt="Symbiotic"
                    title="Symbiotic"
                    sx={{
                      display: "inline-block",
                      width: "30px",
                      mb: 1,
                    }}
                  />
                  <Typography
                    component="div"
                    fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                    textTransform="uppercase"
                  >
                    Symbiotic
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} textAlign="center">
                <Typography variant="h5">Vaults curator</Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ width: "100%", height: "100%" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  gap={2}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={MEVLogo}
                      alt="Symbiotic"
                      title="Symbiotic"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      MEV Capital
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} textAlign="center">
                <Typography variant="h5">Vaults resolver</Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ width: "100%", height: "100%" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  gap={2}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={AragonLogo}
                      alt="Aragon"
                      title="Aragon"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Aragon
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4} md={3} textAlign="center">
                <Typography variant="h5">Distribution platforms</Typography>
                <Box display="flex" justifyContent="center" sx={{ width: "100%" }} gap={1}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={MellowPoints}
                      alt="Mellow"
                      title="Mellow"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Mellow
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={AmphorLogo}
                      alt="Amphor"
                      title="Amphor"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Amphor
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent={{ md: "space-between", sm: "center" }}
              columnSpacing={1}
              rowSpacing={3}
              sx={{ mt: 1 }}
            >
              <Grid item xs={12} sm={12} md={6} textAlign="center">
                <Typography variant="h5">Node operators</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" gap="4px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={NodeinfraLogo}
                      alt="Nodeinfra"
                      title="Nodeinfra"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Nodeinfra
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={ChorusoneLogo}
                      alt="Chorusone"
                      title="Chorusone"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Chorus One
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={FigmentLogo}
                      alt="Figment"
                      title="Figment"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Figment
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={BlockscapeLogo}
                      alt="Blockscape"
                      title="Blockscape"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Blockscape
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={LuganodesLogo}
                      alt="Luganodes"
                      title="Luganodes"
                      sx={{
                        display: "inline-block",
                        width: "40px",
                        height: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Luganodes
                    </Typography>
                  </Box>
                  {/* <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={KilnLogo}
                      alt="Kiln"
                      title="Kiln"
                      sx={{
                        display: "inline-block",
                        height: "30px",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography component="div" fontSize="0.75rem" textTransform="uppercase">
                      Kiln
                    </Typography>
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} textAlign="center">
                <Typography variant="h5">Symbiotic network</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" gap="4px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={EthenaLogo}
                      alt="Ethena"
                      title="Ethena"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Ethena
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={LombardLogo}
                      alt="Lombard"
                      title="Lombard"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Lombard
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={RedstoneLogo}
                      alt="Redstone"
                      title="Redstone"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Redstone
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={HyperlaneLogo}
                      alt="Hyperlane"
                      title="Hyperlane"
                      sx={{
                        display: "inline-block",
                        width: "30px",
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Hyperlane
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={PrimevLogo}
                      alt="Primev"
                      title="Primev"
                      sx={{
                        display: "inline-block",
                        height: 30,
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      Primev
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", mt: 2 }}
                    flexDirection="column"
                  >
                    <CardMedia
                      component="img"
                      src={HyvedaLogo}
                      alt="HyveDA"
                      title="HyveDA"
                      sx={{
                        display: "inline-block",
                        height: 30,
                        width: 30,
                        mb: 1,
                      }}
                    />
                    <Typography
                      component="div"
                      fontSize={{ xs: "0.6rem", md: "0.7rem", lg: "0.75rem" }}
                      textTransform="uppercase"
                    >
                      HyveDA
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </AnimationOnScroll>
        </Box>
      </Container>
    </Box>
  );
};

export default RestakenToken;
