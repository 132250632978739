export const AMPHORAPP_URL = "https://app.amphor.io/";
export const AMPHORQUEST_URL = "https://quests.amphor.io/";
export const AMPHORLRT_URL = "https://app.amphor.io/restake?token=amphrETH";
export const AMPHORBTC_URL = "https://app.amphor.io/restake?token=amphrBTC";
export const AMPHOR_BRIDGE_URL = "https://amphor.hl.superbridge.app/";
export const AMPHORLIQUIDITY_URL = "https://app.amphor.io/earn";
export const ADDITIONAL_URL = "";
export const MEDIUM_URL = "https://medium.com/@Amphor";
export const GITBOOK_URL = "https://defivaults.gitbook.io/amphor/";
export const GITHUB_URL = "https://github.com/orgs/AmphorProtocol/repositories";
export const TWITTER_URL = "https://twitter.com/Amphor_io";
export const DISCORD_URL = "";
export const TELEGRAM_URL = "https://t.me/amphor_official";
export const DEFI_LLAMA_URL = "https://defillama.com/protocol/amphor";

export const KELP_URL = "https://kelpdao.xyz";
export const ETHERFI_URL = "https://www.ether.fi";
export const RENZO_URL = "https://www.renzoprotocol.com";
export const EUCLID_URL = "https://euclidfinance.io";
export const SYMBIOTIC_URL = "https://app.symbiotic.fi";
export const BEDROCK_URL = "https://www.bedrock.technology/";
export const EIGEN_URL = "https://www.eigenlayer.xyz/";
export const EIGENDA_URL = "https://docs.eigenlayer.xyz/eigenda/overview/";
export const ETHEREUM_URL = "https://ethereum.foundation";
export const POLYGON_URL = "https://polygon.technology/polygon-zkevm";
export const MEV_URL = "https://mevcapital.com/";
export const XBTO_URL = "https://www.xbto.com/";
export const MELLOW_URL = "https://mellow.finance/";
export const ZIRCUIT_URL = "https://www.zircuit.com/";
export const BASE_URL = "https://www.base.org/";

export const SALUS_URL = "https://github.com/AmphorProtocol/synthetic-vault/blob/main/audits/Salus_final_report.pdf";
export const BAILSEC_URL =
  "https://github.com/AmphorProtocol/synthetic-vault/blob/main/audits/Bailsec_final_report.pdf";
export const SHERLOCK_URL = "https://www.sherlock.xyz/";
export const OXORIO_URL = "https://oxor.io/";
